
import Vue from 'vue';

export const AUTH_COOKIE_REFRESH_TOKEN = 'oauthRefreshToken';

declare interface State {
  redirecting: boolean,
}

export default Vue.extend({
  name: 'Login',
  data(): State {
    return {
      redirecting: false,
    };
  },
  created() {
    if (this.$route.name === 'auth.login' && this.$auth.hasRefreshToken()) {
      // Only redirect if user is entering via external link
      console.log('User has a refresh token, refreshing authentication token');
      this.$auth.createAndRefresh()
        .then(() => {
          this.$router.push({ name: 'portal.calendar.show' });
        });
    } else if (this.$route.name === 'auth.relogin') {
      // In case of re-login flow, keep user in current view to avoid infinity redirect loop
      // Relogin flow indicates that user received unexpected unauthorized error, clear cookies just in case
      console.log('User authentication failed (relogin), removing cookie');
      this.$cookies.remove(AUTH_COOKIE_REFRESH_TOKEN);
    } else {
      console.log('User is not authenticated, showing normal login page');
    }
  },
  methods: {
    onLogin() {
      console.log('Redirecting to authentication page');
      this.redirecting = true;
      window.location.href = this.$auth.getAuthenticationUri();
    },
  },
});
