/* tslint:disable */
/* eslint-disable */
/**
 * Brontly - Booking API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Appointment public info from end-user perspective.
 * @export
 * @interface AppointmentInfo
 */
export interface AppointmentInfo {
    /**
     * The ID of the booked appointment.
     * @type {string}
     * @memberof AppointmentInfo
     */
    'id': string;
    /**
     * 
     * @type {AppointmentStatusEnum}
     * @memberof AppointmentInfo
     */
    'status': AppointmentStatusEnum;
    /**
     * The start time of the appointment.
     * @type {string}
     * @memberof AppointmentInfo
     */
    'startTime': string;
    /**
     * The end time of the appointment.
     * @type {string}
     * @memberof AppointmentInfo
     */
    'endTime': string;
    /**
     * 
     * @type {ContactInfo}
     * @memberof AppointmentInfo
     */
    'contactInfo': ContactInfo;
    /**
     * 
     * @type {AppointmentType}
     * @memberof AppointmentInfo
     */
    'appointmentType'?: AppointmentType;
}
/**
 * A list of appointment statuses.
 * @export
 * @enum {string}
 */

export const AppointmentStatusEnum = {
    Pending: 'PENDING',
    Confirmed: 'CONFIRMED',
    Failed: 'FAILED',
    Cancelled: 'CANCELLED'
} as const;

export type AppointmentStatusEnum = typeof AppointmentStatusEnum[keyof typeof AppointmentStatusEnum];


/**
 * Appointment type info a business provides.
 * @export
 * @interface AppointmentType
 */
export interface AppointmentType {
    /**
     * Appointment type ID (UUID).
     * @type {string}
     * @memberof AppointmentType
     */
    'id': string;
    /**
     * Appointment type dispay name.
     * @type {string}
     * @memberof AppointmentType
     */
    'name': string;
    /**
     * Appointment type additional information.
     * @type {string}
     * @memberof AppointmentType
     */
    'description'?: string;
    /**
     * Appointment type duration in minutes.
     * @type {number}
     * @memberof AppointmentType
     */
    'duration': number;
}
/**
 * List of available times on a specific date.
 * @export
 * @interface AvailableTimesInfo
 */
export interface AvailableTimesInfo {
    /**
     * The specific date value.
     * @type {string}
     * @memberof AvailableTimesInfo
     */
    'date': string;
    /**
     * List of available times on give day as in \'minute of the day\'.
     * @type {Array<number>}
     * @memberof AvailableTimesInfo
     */
    'times': Array<number>;
}
/**
 * Request to book an appointment.
 * @export
 * @interface BookAppointmentRequest
 */
export interface BookAppointmentRequest {
    /**
     * 
     * @type {string}
     * @memberof BookAppointmentRequest
     */
    'startTime': string;
    /**
     * 
     * @type {ContactInfo}
     * @memberof BookAppointmentRequest
     */
    'contactInfo': ContactInfo;
}
/**
 * Response to book an appointment.
 * @export
 * @interface BookAppointmentResponse
 */
export interface BookAppointmentResponse {
    /**
     * 
     * @type {AppointmentInfo}
     * @memberof BookAppointmentResponse
     */
    'appointment': AppointmentInfo;
}
/**
 * Main configuration for the booking scheduler.
 * @export
 * @interface BookingScheduler
 */
export interface BookingScheduler {
    /**
     * Markup text for additional information view.
     * @type {string}
     * @memberof BookingScheduler
     */
    'additionalInformation'?: string;
    /**
     * Markup text for terms and conditions view.
     * @type {string}
     * @memberof BookingScheduler
     */
    'termsAndConditions'?: string;
}
/**
 * Business public info.
 * @export
 * @interface BusinessInfo
 */
export interface BusinessInfo {
    /**
     * The business ID (slug-case).
     * @type {string}
     * @memberof BusinessInfo
     */
    'id': string;
    /**
     * The business name for public display value.
     * @type {string}
     * @memberof BusinessInfo
     */
    'name': string;
    /**
     * The business time zone, used to format times everywhere for this business.
     * @type {string}
     * @memberof BusinessInfo
     */
    'timezone': string;
    /**
     * 
     * @type {BookingScheduler}
     * @memberof BusinessInfo
     */
    'scheduler': BookingScheduler;
}
/**
 * Appointment contact info.
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
    /**
     * The contact name for the appointment.
     * @type {string}
     * @memberof ContactInfo
     */
    'name': string;
    /**
     * The contact email for the appointment.
     * @type {string}
     * @memberof ContactInfo
     */
    'email': string;
    /**
     * The contact phone for the appointment.
     * @type {string}
     * @memberof ContactInfo
     */
    'phone'?: string;
}
/**
 * Response for a specific appointment information.
 * @export
 * @interface GetAppointmentInfoResponse
 */
export interface GetAppointmentInfoResponse {
    /**
     * 
     * @type {AppointmentInfo}
     * @memberof GetAppointmentInfoResponse
     */
    'appointment': AppointmentInfo;
}
/**
 * List of appointment types for a business.
 * @export
 * @interface GetAppointmentTypesResponse
 */
export interface GetAppointmentTypesResponse {
    /**
     * List of appointment types the business provides.
     * @type {Array<AppointmentType>}
     * @memberof GetAppointmentTypesResponse
     */
    'appointmentTypes': Array<AppointmentType>;
}
/**
 * Response for available times for a specific appointment type.
 * @export
 * @interface GetAvailableTimesResponse
 */
export interface GetAvailableTimesResponse {
    /**
     * List of available dates and times the appointment type is available for booking. The data is in form of an associative array (date => [list of times in minutes]).
     * @type {Array<AvailableTimesInfo>}
     * @memberof GetAvailableTimesResponse
     */
    'availableTimes': Array<AvailableTimesInfo>;
}
/**
 * Response for business information for appointment booking.
 * @export
 * @interface GetBusinessInfoResponse
 */
export interface GetBusinessInfoResponse {
    /**
     * 
     * @type {BusinessInfo}
     * @memberof GetBusinessInfoResponse
     */
    'businessInfo': BusinessInfo;
}

/**
 * BookingApi - axios parameter creator
 * @export
 */
export const BookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Books an appointment.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {BookAppointmentRequest} bookAppointmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookAppointment: async (businessId: string, appointmentTypeId: string, bookAppointmentRequest: BookAppointmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('bookAppointment', 'businessId', businessId)
            // verify required parameter 'appointmentTypeId' is not null or undefined
            assertParamExists('bookAppointment', 'appointmentTypeId', appointmentTypeId)
            // verify required parameter 'bookAppointmentRequest' is not null or undefined
            assertParamExists('bookAppointment', 'bookAppointmentRequest', bookAppointmentRequest)
            const localVarPath = `/api/public/{businessId}/appointment-type/{appointmentTypeId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"appointmentTypeId"}}`, encodeURIComponent(String(appointmentTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookAppointmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancels appointment.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointment: async (businessId: string, appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('cancelAppointment', 'businessId', businessId)
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('cancelAppointment', 'appointmentId', appointmentId)
            const localVarPath = `/api/public/{businessId}/appointment/{appointmentId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns appointment information.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentInfo: async (businessId: string, appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getAppointmentInfo', 'businessId', businessId)
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('getAppointmentInfo', 'appointmentId', appointmentId)
            const localVarPath = `/api/public/{businessId}/appointment/{appointmentId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List available appiointment types for the business.
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTypes: async (businessId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getAppointmentTypes', 'businessId', businessId)
            const localVarPath = `/api/public/{businessId}/appointment-type`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List available times for booking a specific appointment type.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {string} [startDate] The query start date, current date if absent.
         * @param {string} [endDate] The query end date, end of current month if absent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTimes: async (businessId: string, appointmentTypeId: string, startDate?: string, endDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getAvailableTimes', 'businessId', businessId)
            // verify required parameter 'appointmentTypeId' is not null or undefined
            assertParamExists('getAvailableTimes', 'appointmentTypeId', appointmentTypeId)
            const localVarPath = `/api/public/{businessId}/appointment-type/{appointmentTypeId}/available-slots`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"appointmentTypeId"}}`, encodeURIComponent(String(appointmentTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Business overview and main scheduler configuration.
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessInfo: async (businessId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getBusinessInfo', 'businessId', businessId)
            const localVarPath = `/api/public/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingApi - functional programming interface
 * @export
 */
export const BookingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingApiAxiosParamCreator(configuration)
    return {
        /**
         * Books an appointment.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {BookAppointmentRequest} bookAppointmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookAppointment(businessId: string, appointmentTypeId: string, bookAppointmentRequest: BookAppointmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookAppointment(businessId, appointmentTypeId, bookAppointmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancels appointment.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelAppointment(businessId: string, appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppointmentInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAppointment(businessId, appointmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns appointment information.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentInfo(businessId: string, appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppointmentInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentInfo(businessId, appointmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List available appiointment types for the business.
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentTypes(businessId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppointmentTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentTypes(businessId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List available times for booking a specific appointment type.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {string} [startDate] The query start date, current date if absent.
         * @param {string} [endDate] The query end date, end of current month if absent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableTimes(businessId: string, appointmentTypeId: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAvailableTimesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableTimes(businessId, appointmentTypeId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Business overview and main scheduler configuration.
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBusinessInfo(businessId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBusinessInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessInfo(businessId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingApi - factory interface
 * @export
 */
export const BookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingApiFp(configuration)
    return {
        /**
         * Books an appointment.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {BookAppointmentRequest} bookAppointmentRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookAppointment(businessId: string, appointmentTypeId: string, bookAppointmentRequest: BookAppointmentRequest, options?: any): AxiosPromise<BookAppointmentResponse> {
            return localVarFp.bookAppointment(businessId, appointmentTypeId, bookAppointmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancels appointment.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelAppointment(businessId: string, appointmentId: string, options?: any): AxiosPromise<GetAppointmentInfoResponse> {
            return localVarFp.cancelAppointment(businessId, appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns appointment information.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentInfo(businessId: string, appointmentId: string, options?: any): AxiosPromise<GetAppointmentInfoResponse> {
            return localVarFp.getAppointmentInfo(businessId, appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * List available appiointment types for the business.
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTypes(businessId: string, options?: any): AxiosPromise<GetAppointmentTypesResponse> {
            return localVarFp.getAppointmentTypes(businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * List available times for booking a specific appointment type.
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {string} [startDate] The query start date, current date if absent.
         * @param {string} [endDate] The query end date, end of current month if absent.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableTimes(businessId: string, appointmentTypeId: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<GetAvailableTimesResponse> {
            return localVarFp.getAvailableTimes(businessId, appointmentTypeId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Business overview and main scheduler configuration.
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessInfo(businessId: string, options?: any): AxiosPromise<GetBusinessInfoResponse> {
            return localVarFp.getBusinessInfo(businessId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingApi - object-oriented interface
 * @export
 * @class BookingApi
 * @extends {BaseAPI}
 */
export class BookingApi extends BaseAPI {
    /**
     * Books an appointment.
     * @param {string} businessId The ID of the business.
     * @param {string} appointmentTypeId The ID of the appointment type.
     * @param {BookAppointmentRequest} bookAppointmentRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public bookAppointment(businessId: string, appointmentTypeId: string, bookAppointmentRequest: BookAppointmentRequest, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).bookAppointment(businessId, appointmentTypeId, bookAppointmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancels appointment.
     * @param {string} businessId The ID of the business.
     * @param {string} appointmentId The ID of the appointment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public cancelAppointment(businessId: string, appointmentId: string, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).cancelAppointment(businessId, appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns appointment information.
     * @param {string} businessId The ID of the business.
     * @param {string} appointmentId The ID of the appointment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public getAppointmentInfo(businessId: string, appointmentId: string, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).getAppointmentInfo(businessId, appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List available appiointment types for the business.
     * @param {string} businessId The ID of the business.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public getAppointmentTypes(businessId: string, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).getAppointmentTypes(businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List available times for booking a specific appointment type.
     * @param {string} businessId The ID of the business.
     * @param {string} appointmentTypeId The ID of the appointment type.
     * @param {string} [startDate] The query start date, current date if absent.
     * @param {string} [endDate] The query end date, end of current month if absent.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public getAvailableTimes(businessId: string, appointmentTypeId: string, startDate?: string, endDate?: string, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).getAvailableTimes(businessId, appointmentTypeId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Business overview and main scheduler configuration.
     * @param {string} businessId The ID of the business.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingApi
     */
    public getBusinessInfo(businessId: string, options?: AxiosRequestConfig) {
        return BookingApiFp(this.configuration).getBusinessInfo(businessId, options).then((request) => request(this.axios, this.basePath));
    }
}


