/* tslint:disable */
/* eslint-disable */
/**
 * Brontly - Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Additional information to be shown in the booking app.
 * @export
 * @interface AdditionalInformation
 */
export interface AdditionalInformation {
    /**
     * Determines if the additional information feature is enabled.
     * @type {boolean}
     * @memberof AdditionalInformation
     */
    'enabled': boolean;
    /**
     * Markup text for additional information view.
     * @type {string}
     * @memberof AdditionalInformation
     */
    'body'?: string;
}
/**
 * Appointment booking information.
 * @export
 * @interface Appointment
 */
export interface Appointment {
    /**
     * The ID of the booked appointment.
     * @type {string}
     * @memberof Appointment
     */
    'id': string;
    /**
     * 
     * @type {AppointmentStatusEnum}
     * @memberof Appointment
     */
    'status': AppointmentStatusEnum;
    /**
     * 
     * @type {Schedule}
     * @memberof Appointment
     */
    'schedule': Schedule;
    /**
     * 
     * @type {Contact}
     * @memberof Appointment
     */
    'contact': Contact;
    /**
     * 
     * @type {AppointmentTypeConf}
     * @memberof Appointment
     */
    'appointmentType'?: AppointmentTypeConf;
}
/**
 * Appointment type scheduling configuration from a single booking perspective.
 * @export
 * @interface AppointmentSchedulingConf
 */
export interface AppointmentSchedulingConf {
    /**
     * 
     * @type {number}
     * @memberof AppointmentSchedulingConf
     */
    'duration': number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentSchedulingConf
     */
    'bufferBefore'?: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentSchedulingConf
     */
    'bufferAfter'?: number;
}
/**
 * A list of appointment statuses.
 * @export
 * @enum {string}
 */

export const AppointmentStatusEnum = {
    Pending: 'PENDING',
    Confirmed: 'CONFIRMED',
    Failed: 'FAILED',
    Cancelled: 'CANCELLED'
} as const;

export type AppointmentStatusEnum = typeof AppointmentStatusEnum[keyof typeof AppointmentStatusEnum];


/**
 * Appointment type configuration data for portal.
 * @export
 * @interface AppointmentTypeConf
 */
export interface AppointmentTypeConf {
    /**
     * The appointment type ID (slug-case).
     * @type {string}
     * @memberof AppointmentTypeConf
     */
    'id': string;
    /**
     * Human readable appointment type name.
     * @type {string}
     * @memberof AppointmentTypeConf
     */
    'name': string;
    /**
     * Appointment type additional information (markdown).
     * @type {string}
     * @memberof AppointmentTypeConf
     */
    'description'?: string;
    /**
     * 
     * @type {AppointmentSchedulingConf}
     * @memberof AppointmentTypeConf
     */
    'appointmentScheduling': AppointmentSchedulingConf;
    /**
     * 
     * @type {GeneralSchedulingConf}
     * @memberof AppointmentTypeConf
     */
    'generalScheduling': GeneralSchedulingConf;
    /**
     * 
     * @type {Array<StartTimesConf>}
     * @memberof AppointmentTypeConf
     */
    'startTimesPerDay': Array<StartTimesConf>;
}
/**
 * Business info from tenant perspective.
 * @export
 * @interface BusinessConf
 */
export interface BusinessConf {
    /**
     * The business ID (slug-case).
     * @type {string}
     * @memberof BusinessConf
     */
    'id': string;
    /**
     * The business name for public display value.
     * @type {string}
     * @memberof BusinessConf
     */
    'name': string;
    /**
     * The business time zone, used to format times everywhere for this business.
     * @type {string}
     * @memberof BusinessConf
     */
    'timezone': string;
    /**
     * 
     * @type {AdditionalInformation}
     * @memberof BusinessConf
     */
    'additionalInformation'?: AdditionalInformation;
    /**
     * 
     * @type {TermsAndConditions}
     * @memberof BusinessConf
     */
    'termsAndConditions'?: TermsAndConditions;
    /**
     * 
     * @type {WorkingHours}
     * @memberof BusinessConf
     */
    'workingHours': WorkingHours;
}
/**
 * Appointment contact info.
 * @export
 * @interface Contact
 */
export interface Contact {
    /**
     * The contact name for the appointment.
     * @type {string}
     * @memberof Contact
     */
    'name': string;
    /**
     * The contact email for the appointment.
     * @type {string}
     * @memberof Contact
     */
    'email': string;
    /**
     * The contact phone for the appointment.
     * @type {string}
     * @memberof Contact
     */
    'phone'?: string;
}
/**
 * Request to create a new appointment.
 * @export
 * @interface CreateAppointmentRequest
 */
export interface CreateAppointmentRequest {
    /**
     * The appointment type id.
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'appointmentTypeId': string;
    /**
     * The start time of the appointment.
     * @type {string}
     * @memberof CreateAppointmentRequest
     */
    'startTime': string;
    /**
     * 
     * @type {Contact}
     * @memberof CreateAppointmentRequest
     */
    'contact': Contact;
}
/**
 * Response for a successful create.
 * @export
 * @interface CreateAppointmentResponse
 */
export interface CreateAppointmentResponse {
    /**
     * 
     * @type {Appointment}
     * @memberof CreateAppointmentResponse
     */
    'appointment': Appointment;
}
/**
 * Request to create a new appointment type.
 * @export
 * @interface CreateAppointmentTypeRequest
 */
export interface CreateAppointmentTypeRequest {
    /**
     * 
     * @type {AppointmentTypeConf}
     * @memberof CreateAppointmentTypeRequest
     */
    'appointmentType': AppointmentTypeConf;
}
/**
 * Response for a successful update.
 * @export
 * @interface CreateAppointmentTypeResponse
 */
export interface CreateAppointmentTypeResponse {
    /**
     * 
     * @type {AppointmentTypeConf}
     * @memberof CreateAppointmentTypeResponse
     */
    'appointmentType': AppointmentTypeConf;
}
/**
 * Create business request.
 * @export
 * @interface CreateBusinessRequest
 */
export interface CreateBusinessRequest {
    /**
     * The tenant ID.
     * @type {string}
     * @memberof CreateBusinessRequest
     */
    'id': string;
}
/**
 * Appointment type scheduling configuration from all bookings perspective perspective.
 * @export
 * @interface GeneralSchedulingConf
 */
export interface GeneralSchedulingConf {
    /**
     * 
     * @type {number}
     * @memberof GeneralSchedulingConf
     */
    'noticeMin'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralSchedulingConf
     */
    'noticeMax'?: number;
    /**
     * 
     * @type {string}
     * @memberof GeneralSchedulingConf
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GeneralSchedulingConf
     */
    'endDate'?: string;
}
/**
 * Response to a specific appointment type configuration for portal.
 * @export
 * @interface GetAppointmentTypeResponse
 */
export interface GetAppointmentTypeResponse {
    /**
     * 
     * @type {AppointmentTypeConf}
     * @memberof GetAppointmentTypeResponse
     */
    'appointmentType': AppointmentTypeConf;
}
/**
 * Response list of appointment types configuration for portal.
 * @export
 * @interface GetAppointmentTypesResponse
 */
export interface GetAppointmentTypesResponse {
    /**
     * 
     * @type {Array<AppointmentTypeConf>}
     * @memberof GetAppointmentTypesResponse
     */
    'appointmentTypes': Array<AppointmentTypeConf>;
}
/**
 * Response list of appointments for portal.
 * @export
 * @interface GetAppointmentsResponse
 */
export interface GetAppointmentsResponse {
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof GetAppointmentsResponse
     */
    'appointments': Array<Appointment>;
}
/**
 * Response for tenant information for portal.
 * @export
 * @interface GetTenantInfoResponse
 */
export interface GetTenantInfoResponse {
    /**
     * 
     * @type {TenantInfo}
     * @memberof GetTenantInfoResponse
     */
    'tenantInfo': TenantInfo;
    /**
     * 
     * @type {BusinessConf}
     * @memberof GetTenantInfoResponse
     */
    'business'?: BusinessConf;
}
/**
 * Appointment booking information from scheduling perspective.
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * The start time of the appointment buffer.
     * @type {string}
     * @memberof Schedule
     */
    'bufferStart': string;
    /**
     * The start time of the appointment.
     * @type {string}
     * @memberof Schedule
     */
    'startTime': string;
    /**
     * The end time of the appointment.
     * @type {string}
     * @memberof Schedule
     */
    'endTime': string;
    /**
     * The end time of the appointment buffer.
     * @type {string}
     * @memberof Schedule
     */
    'bufferEnd': string;
}
/**
 * An appointment type configuration for a single day.
 * @export
 * @interface StartTimesConf
 */
export interface StartTimesConf {
    /**
     * 
     * @type {WeekdayEnum}
     * @memberof StartTimesConf
     */
    'day': WeekdayEnum;
    /**
     * 
     * @type {Array<TimeInfo>}
     * @memberof StartTimesConf
     */
    'startTimes': Array<TimeInfo>;
}
/**
 * Tenant base info.
 * @export
 * @interface TenantInfo
 */
export interface TenantInfo {
    /**
     * The tenant ID.
     * @type {string}
     * @memberof TenantInfo
     */
    'id': string;
}
/**
 * Terms and conditions to be shown in the booking app.
 * @export
 * @interface TermsAndConditions
 */
export interface TermsAndConditions {
    /**
     * Determines if the terms and conditions feature is enabled.
     * @type {boolean}
     * @memberof TermsAndConditions
     */
    'enabled': boolean;
    /**
     * Markup text for terms and conditions view.
     * @type {string}
     * @memberof TermsAndConditions
     */
    'body'?: string;
}
/**
 * Time point information in a day (timezone agnostic).
 * @export
 * @interface TimeInfo
 */
export interface TimeInfo {
    /**
     * 
     * @type {number}
     * @memberof TimeInfo
     */
    'hour': number;
    /**
     * 
     * @type {number}
     * @memberof TimeInfo
     */
    'minute': number;
}
/**
 * Request to update an existing appointment.
 * @export
 * @interface UpdateAppointmentRequest
 */
export interface UpdateAppointmentRequest {
    /**
     * The appointment type id.
     * @type {string}
     * @memberof UpdateAppointmentRequest
     */
    'appointmentTypeId': string;
    /**
     * The start time of the appointment.
     * @type {string}
     * @memberof UpdateAppointmentRequest
     */
    'startTime': string;
    /**
     * 
     * @type {Contact}
     * @memberof UpdateAppointmentRequest
     */
    'contact': Contact;
}
/**
 * Response for a successful update.
 * @export
 * @interface UpdateAppointmentResponse
 */
export interface UpdateAppointmentResponse {
    /**
     * 
     * @type {Appointment}
     * @memberof UpdateAppointmentResponse
     */
    'appointment': Appointment;
}
/**
 * Request to update an existing appointment type.
 * @export
 * @interface UpdateAppointmentTypeRequest
 */
export interface UpdateAppointmentTypeRequest {
    /**
     * 
     * @type {AppointmentTypeConf}
     * @memberof UpdateAppointmentTypeRequest
     */
    'appointmentType': AppointmentTypeConf;
}
/**
 * Response for a successful update.
 * @export
 * @interface UpdateAppointmentTypeResponse
 */
export interface UpdateAppointmentTypeResponse {
    /**
     * 
     * @type {AppointmentTypeConf}
     * @memberof UpdateAppointmentTypeResponse
     */
    'appointmentType': AppointmentTypeConf;
}
/**
 * Update business info - only information provided is updated (pathc concept).
 * @export
 * @interface UpdateBusinessRequest
 */
export interface UpdateBusinessRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBusinessRequest
     */
    'timezone'?: string;
    /**
     * 
     * @type {AdditionalInformation}
     * @memberof UpdateBusinessRequest
     */
    'additionalInformation'?: AdditionalInformation;
    /**
     * 
     * @type {TermsAndConditions}
     * @memberof UpdateBusinessRequest
     */
    'termsAndConditions'?: TermsAndConditions;
    /**
     * 
     * @type {WorkingHours}
     * @memberof UpdateBusinessRequest
     */
    'workingHours'?: WorkingHours;
}
/**
 * Response for creating/updating business, full info is returned.
 * @export
 * @interface UpdateBusinessResponse
 */
export interface UpdateBusinessResponse {
    /**
     * 
     * @type {BusinessConf}
     * @memberof UpdateBusinessResponse
     */
    'business': BusinessConf;
}
/**
 * A list of weekdays.
 * @export
 * @enum {string}
 */

export const WeekdayEnum = {
    Monday: 'monday',
    Tuesday: 'tuesday',
    Wednesday: 'wednesday',
    Thursday: 'thursday',
    Friday: 'friday',
    Saturday: 'saturday',
    Sunday: 'sunday'
} as const;

export type WeekdayEnum = typeof WeekdayEnum[keyof typeof WeekdayEnum];


/**
 * Structure to define a working hours schedule for a full week.
 * @export
 * @interface WorkingHours
 */
export interface WorkingHours {
    /**
     * 
     * @type {Array<WorkingHoursDay>}
     * @memberof WorkingHours
     */
    'days': Array<WorkingHoursDay>;
}
/**
 * A working hours configuration for a single day
 * @export
 * @interface WorkingHoursDay
 */
export interface WorkingHoursDay {
    /**
     * 
     * @type {WeekdayEnum}
     * @memberof WorkingHoursDay
     */
    'day': WeekdayEnum;
    /**
     * 
     * @type {Array<WorkingHoursSlot>}
     * @memberof WorkingHoursDay
     */
    'slots': Array<WorkingHoursSlot>;
}
/**
 * A single continous slot of work time.
 * @export
 * @interface WorkingHoursSlot
 */
export interface WorkingHoursSlot {
    /**
     * 
     * @type {TimeInfo}
     * @memberof WorkingHoursSlot
     */
    'start': TimeInfo;
    /**
     * 
     * @type {TimeInfo}
     * @memberof WorkingHoursSlot
     */
    'end': TimeInfo;
}

/**
 * PortalApi - axios parameter creator
 * @export
 */
export const PortalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {CreateAppointmentRequest} [createAppointmentRequest] Expected request for create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointment: async (businessId: string, createAppointmentRequest?: CreateAppointmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('createAppointment', 'businessId', businessId)
            const localVarPath = `/api/portal/business/{businessId}/appointment`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppointmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {CreateAppointmentTypeRequest} [createAppointmentTypeRequest] Expected request for create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointmentType: async (businessId: string, createAppointmentTypeRequest?: CreateAppointmentTypeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('createAppointmentType', 'businessId', businessId)
            const localVarPath = `/api/portal/business/{businessId}/appointment-type`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAppointmentTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateBusinessRequest} createBusinessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusiness: async (createBusinessRequest: CreateBusinessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBusinessRequest' is not null or undefined
            assertParamExists('createBusiness', 'createBusinessRequest', createBusinessRequest)
            const localVarPath = `/api/portal/business`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBusinessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppointment: async (businessId: string, appointmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('deleteAppointment', 'businessId', businessId)
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('deleteAppointment', 'appointmentId', appointmentId)
            const localVarPath = `/api/portal/business/{businessId}/appointment/{appointmentId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppointmentType: async (businessId: string, appointmentTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('deleteAppointmentType', 'businessId', businessId)
            // verify required parameter 'appointmentTypeId' is not null or undefined
            assertParamExists('deleteAppointmentType', 'appointmentTypeId', appointmentTypeId)
            const localVarPath = `/api/portal/business/{businessId}/appointment-type/{appointmentTypeId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"appointmentTypeId"}}`, encodeURIComponent(String(appointmentTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusiness: async (businessId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('deleteBusiness', 'businessId', businessId)
            const localVarPath = `/api/portal/business/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentType: async (businessId: string, appointmentTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getAppointmentType', 'businessId', businessId)
            // verify required parameter 'appointmentTypeId' is not null or undefined
            assertParamExists('getAppointmentType', 'appointmentTypeId', appointmentTypeId)
            const localVarPath = `/api/portal/business/{businessId}/appointment-type/{appointmentTypeId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"appointmentTypeId"}}`, encodeURIComponent(String(appointmentTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTypes: async (businessId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getAppointmentTypes', 'businessId', businessId)
            const localVarPath = `/api/portal/business/{businessId}/appointment-type`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointments: async (businessId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('getAppointments', 'businessId', businessId)
            const localVarPath = `/api/portal/business/{businessId}/appointment`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/portal/tenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {UpdateAppointmentRequest} [updateAppointmentRequest] Expected request for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointment: async (businessId: string, appointmentId: string, updateAppointmentRequest?: UpdateAppointmentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('updateAppointment', 'businessId', businessId)
            // verify required parameter 'appointmentId' is not null or undefined
            assertParamExists('updateAppointment', 'appointmentId', appointmentId)
            const localVarPath = `/api/portal/business/{businessId}/appointment/{appointmentId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"appointmentId"}}`, encodeURIComponent(String(appointmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppointmentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {UpdateAppointmentTypeRequest} [updateAppointmentTypeRequest] Expected request for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointmentType: async (businessId: string, appointmentTypeId: string, updateAppointmentTypeRequest?: UpdateAppointmentTypeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('updateAppointmentType', 'businessId', businessId)
            // verify required parameter 'appointmentTypeId' is not null or undefined
            assertParamExists('updateAppointmentType', 'appointmentTypeId', appointmentTypeId)
            const localVarPath = `/api/portal/business/{businessId}/appointment-type/{appointmentTypeId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)))
                .replace(`{${"appointmentTypeId"}}`, encodeURIComponent(String(appointmentTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAppointmentTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {UpdateBusinessRequest} updateBusinessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusiness: async (businessId: string, updateBusinessRequest: UpdateBusinessRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'businessId' is not null or undefined
            assertParamExists('updateBusiness', 'businessId', businessId)
            // verify required parameter 'updateBusinessRequest' is not null or undefined
            assertParamExists('updateBusiness', 'updateBusinessRequest', updateBusinessRequest)
            const localVarPath = `/api/portal/business/{businessId}`
                .replace(`{${"businessId"}}`, encodeURIComponent(String(businessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication portalBearerSecurity required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateBusinessRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortalApi - functional programming interface
 * @export
 */
export const PortalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {CreateAppointmentRequest} [createAppointmentRequest] Expected request for create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppointment(businessId: string, createAppointmentRequest?: CreateAppointmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppointment(businessId, createAppointmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {CreateAppointmentTypeRequest} [createAppointmentTypeRequest] Expected request for create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAppointmentType(businessId: string, createAppointmentTypeRequest?: CreateAppointmentTypeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateAppointmentTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAppointmentType(businessId, createAppointmentTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateBusinessRequest} createBusinessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBusiness(createBusinessRequest: CreateBusinessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBusinessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBusiness(createBusinessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppointment(businessId: string, appointmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppointment(businessId, appointmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppointmentType(businessId: string, appointmentTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppointmentType(businessId, appointmentTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBusiness(businessId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBusiness(businessId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentType(businessId: string, appointmentTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppointmentTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentType(businessId, appointmentTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointmentTypes(businessId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppointmentTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointmentTypes(businessId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppointments(businessId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAppointmentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppointments(businessId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTenantInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTenantInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTenantInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {UpdateAppointmentRequest} [updateAppointmentRequest] Expected request for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppointment(businessId: string, appointmentId: string, updateAppointmentRequest?: UpdateAppointmentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAppointmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppointment(businessId, appointmentId, updateAppointmentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {UpdateAppointmentTypeRequest} [updateAppointmentTypeRequest] Expected request for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAppointmentType(businessId: string, appointmentTypeId: string, updateAppointmentTypeRequest?: UpdateAppointmentTypeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateAppointmentTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAppointmentType(businessId, appointmentTypeId, updateAppointmentTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {UpdateBusinessRequest} updateBusinessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBusiness(businessId: string, updateBusinessRequest: UpdateBusinessRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBusinessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBusiness(businessId, updateBusinessRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortalApi - factory interface
 * @export
 */
export const PortalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortalApiFp(configuration)
    return {
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {CreateAppointmentRequest} [createAppointmentRequest] Expected request for create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointment(businessId: string, createAppointmentRequest?: CreateAppointmentRequest, options?: any): AxiosPromise<CreateAppointmentResponse> {
            return localVarFp.createAppointment(businessId, createAppointmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {CreateAppointmentTypeRequest} [createAppointmentTypeRequest] Expected request for create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAppointmentType(businessId: string, createAppointmentTypeRequest?: CreateAppointmentTypeRequest, options?: any): AxiosPromise<CreateAppointmentTypeResponse> {
            return localVarFp.createAppointmentType(businessId, createAppointmentTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateBusinessRequest} createBusinessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBusiness(createBusinessRequest: CreateBusinessRequest, options?: any): AxiosPromise<UpdateBusinessResponse> {
            return localVarFp.createBusiness(createBusinessRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppointment(businessId: string, appointmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAppointment(businessId, appointmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppointmentType(businessId: string, appointmentTypeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAppointmentType(businessId, appointmentTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBusiness(businessId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBusiness(businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentType(businessId: string, appointmentTypeId: string, options?: any): AxiosPromise<GetAppointmentTypeResponse> {
            return localVarFp.getAppointmentType(businessId, appointmentTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointmentTypes(businessId: string, options?: any): AxiosPromise<GetAppointmentTypesResponse> {
            return localVarFp.getAppointmentTypes(businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppointments(businessId: string, options?: any): AxiosPromise<GetAppointmentsResponse> {
            return localVarFp.getAppointments(businessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTenantInfo(options?: any): AxiosPromise<GetTenantInfoResponse> {
            return localVarFp.getTenantInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentId The ID of the appointment.
         * @param {UpdateAppointmentRequest} [updateAppointmentRequest] Expected request for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointment(businessId: string, appointmentId: string, updateAppointmentRequest?: UpdateAppointmentRequest, options?: any): AxiosPromise<UpdateAppointmentResponse> {
            return localVarFp.updateAppointment(businessId, appointmentId, updateAppointmentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {string} appointmentTypeId The ID of the appointment type.
         * @param {UpdateAppointmentTypeRequest} [updateAppointmentTypeRequest] Expected request for update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppointmentType(businessId: string, appointmentTypeId: string, updateAppointmentTypeRequest?: UpdateAppointmentTypeRequest, options?: any): AxiosPromise<UpdateAppointmentTypeResponse> {
            return localVarFp.updateAppointmentType(businessId, appointmentTypeId, updateAppointmentTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} businessId The ID of the business.
         * @param {UpdateBusinessRequest} updateBusinessRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBusiness(businessId: string, updateBusinessRequest: UpdateBusinessRequest, options?: any): AxiosPromise<UpdateBusinessResponse> {
            return localVarFp.updateBusiness(businessId, updateBusinessRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortalApi - object-oriented interface
 * @export
 * @class PortalApi
 * @extends {BaseAPI}
 */
export class PortalApi extends BaseAPI {
    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {CreateAppointmentRequest} [createAppointmentRequest] Expected request for create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public createAppointment(businessId: string, createAppointmentRequest?: CreateAppointmentRequest, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).createAppointment(businessId, createAppointmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {CreateAppointmentTypeRequest} [createAppointmentTypeRequest] Expected request for create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public createAppointmentType(businessId: string, createAppointmentTypeRequest?: CreateAppointmentTypeRequest, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).createAppointmentType(businessId, createAppointmentTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateBusinessRequest} createBusinessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public createBusiness(createBusinessRequest: CreateBusinessRequest, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).createBusiness(createBusinessRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {string} appointmentId The ID of the appointment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public deleteAppointment(businessId: string, appointmentId: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).deleteAppointment(businessId, appointmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {string} appointmentTypeId The ID of the appointment type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public deleteAppointmentType(businessId: string, appointmentTypeId: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).deleteAppointmentType(businessId, appointmentTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public deleteBusiness(businessId: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).deleteBusiness(businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {string} appointmentTypeId The ID of the appointment type.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public getAppointmentType(businessId: string, appointmentTypeId: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).getAppointmentType(businessId, appointmentTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public getAppointmentTypes(businessId: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).getAppointmentTypes(businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public getAppointments(businessId: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).getAppointments(businessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public getTenantInfo(options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).getTenantInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {string} appointmentId The ID of the appointment.
     * @param {UpdateAppointmentRequest} [updateAppointmentRequest] Expected request for update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public updateAppointment(businessId: string, appointmentId: string, updateAppointmentRequest?: UpdateAppointmentRequest, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).updateAppointment(businessId, appointmentId, updateAppointmentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {string} appointmentTypeId The ID of the appointment type.
     * @param {UpdateAppointmentTypeRequest} [updateAppointmentTypeRequest] Expected request for update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public updateAppointmentType(businessId: string, appointmentTypeId: string, updateAppointmentTypeRequest?: UpdateAppointmentTypeRequest, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).updateAppointmentType(businessId, appointmentTypeId, updateAppointmentTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} businessId The ID of the business.
     * @param {UpdateBusinessRequest} updateBusinessRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public updateBusiness(businessId: string, updateBusinessRequest: UpdateBusinessRequest, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).updateBusiness(businessId, updateBusinessRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


