/* tslint:disable */
/* eslint-disable */
/**
 * Brontly - Multipoll API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Request to create a new multipoll.
 * @export
 * @interface CreateMultipollRequest
 */
export interface CreateMultipollRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateMultipollRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateMultipollRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMultipollRequest
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateMultipollRequest
     */
    'contactEmail'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateMultipollRequest
     */
    'multipollDates': Array<string>;
}
/**
 * Response to multipoll creation.
 * @export
 * @interface CreateMultipollResponse
 */
export interface CreateMultipollResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateMultipollResponse
     */
    'authToken': string;
    /**
     * 
     * @type {MultipollInfo}
     * @memberof CreateMultipollResponse
     */
    'multipoll': MultipollInfo;
    /**
     * 
     * @type {MultipollAnswer}
     * @memberof CreateMultipollResponse
     */
    'answer': MultipollAnswer;
    /**
     * 
     * @type {string}
     * @memberof CreateMultipollResponse
     */
    'answerToken': string;
}
/**
 * Response for multipoll information.
 * @export
 * @interface GetMultipollInfoResponse
 */
export interface GetMultipollInfoResponse {
    /**
     * 
     * @type {MultipollInfo}
     * @memberof GetMultipollInfoResponse
     */
    'multipoll': MultipollInfo;
    /**
     * 
     * @type {MultipollAnswer}
     * @memberof GetMultipollInfoResponse
     */
    'answer'?: MultipollAnswer;
    /**
     * 
     * @type {Array<MultipollAnswer>}
     * @memberof GetMultipollInfoResponse
     */
    'otherAnswers': Array<MultipollAnswer>;
}
/**
 * Multipoll answer from a single person.
 * @export
 * @interface MultipollAnswer
 */
export interface MultipollAnswer {
    /**
     * 
     * @type {string}
     * @memberof MultipollAnswer
     */
    'contactName': string;
    /**
     * 
     * @type {Array<MultipollChoiceInput>}
     * @memberof MultipollAnswer
     */
    'multipollChoiceInputs': Array<MultipollChoiceInput>;
}
/**
 * Multipoll single choice/option/item.
 * @export
 * @interface MultipollChoice
 */
export interface MultipollChoice {
    /**
     * 
     * @type {string}
     * @memberof MultipollChoice
     */
    'date': string;
}
/**
 * A list of multipoll choice inputs.
 * @export
 * @enum {string}
 */

export const MultipollChoiceEnum = {
    Yes: 'YES',
    Maybe: 'MAYBE',
    No: 'NO'
} as const;

export type MultipollChoiceEnum = typeof MultipollChoiceEnum[keyof typeof MultipollChoiceEnum];


/**
 * Multipoll choice input info.
 * @export
 * @interface MultipollChoiceInput
 */
export interface MultipollChoiceInput {
    /**
     * 
     * @type {string}
     * @memberof MultipollChoiceInput
     */
    'date': string;
    /**
     * 
     * @type {MultipollChoiceEnum}
     * @memberof MultipollChoiceInput
     */
    'choice': MultipollChoiceEnum;
}
/**
 * Multipoll public info from end-user perspective.
 * @export
 * @interface MultipollInfo
 */
export interface MultipollInfo {
    /**
     * 
     * @type {string}
     * @memberof MultipollInfo
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof MultipollInfo
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof MultipollInfo
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof MultipollInfo
     */
    'contactName': string;
    /**
     * 
     * @type {Array<MultipollChoice>}
     * @memberof MultipollInfo
     */
    'multipollChoices': Array<MultipollChoice>;
}
/**
 * Request to submit a multipoll input.
 * @export
 * @interface SubmitMultipollRequest
 */
export interface SubmitMultipollRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitMultipollRequest
     */
    'contactName': string;
    /**
     * 
     * @type {string}
     * @memberof SubmitMultipollRequest
     */
    'contactEmail'?: string;
    /**
     * 
     * @type {Array<MultipollChoiceInput>}
     * @memberof SubmitMultipollRequest
     */
    'multipollChoiceInputs': Array<MultipollChoiceInput>;
}
/**
 * Response for multipoll submit.
 * @export
 * @interface SubmitMultipollResponse
 */
export interface SubmitMultipollResponse {
    /**
     * 
     * @type {MultipollInfo}
     * @memberof SubmitMultipollResponse
     */
    'multipoll': MultipollInfo;
    /**
     * 
     * @type {MultipollAnswer}
     * @memberof SubmitMultipollResponse
     */
    'answer': MultipollAnswer;
    /**
     * 
     * @type {string}
     * @memberof SubmitMultipollResponse
     */
    'answerToken': string;
    /**
     * 
     * @type {Array<MultipollAnswer>}
     * @memberof SubmitMultipollResponse
     */
    'otherAnswers': Array<MultipollAnswer>;
}

/**
 * MultipollApi - axios parameter creator
 * @export
 */
export const MultipollApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a new multipoll.
         * @param {CreateMultipollRequest} createMultipollRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipoll: async (createMultipollRequest: CreateMultipollRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createMultipollRequest' is not null or undefined
            assertParamExists('createMultipoll', 'createMultipollRequest', createMultipollRequest)
            const localVarPath = `/api/public/multipoll/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createMultipollRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Multipoll public info.
         * @param {string} multipollId The ID of the multipoll.
         * @param {string} [answerToken] The authentication token for a multipoll answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipollInfo: async (multipollId: string, answerToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'multipollId' is not null or undefined
            assertParamExists('getMultipollInfo', 'multipollId', multipollId)
            const localVarPath = `/api/public/multipoll/{multipollId}`
                .replace(`{${"multipollId"}}`, encodeURIComponent(String(multipollId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (answerToken !== undefined && answerToken !== null) {
                localVarHeaderParameter['answerToken'] = String(answerToken);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit multipoll input.
         * @param {string} multipollId The ID of the multipoll.
         * @param {SubmitMultipollRequest} submitMultipollRequest 
         * @param {string} [answerToken] The authentication token for a multipoll answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitMultipoll: async (multipollId: string, submitMultipollRequest: SubmitMultipollRequest, answerToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'multipollId' is not null or undefined
            assertParamExists('submitMultipoll', 'multipollId', multipollId)
            // verify required parameter 'submitMultipollRequest' is not null or undefined
            assertParamExists('submitMultipoll', 'submitMultipollRequest', submitMultipollRequest)
            const localVarPath = `/api/public/multipoll/{multipollId}`
                .replace(`{${"multipollId"}}`, encodeURIComponent(String(multipollId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (answerToken !== undefined && answerToken !== null) {
                localVarHeaderParameter['answerToken'] = String(answerToken);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submitMultipollRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MultipollApi - functional programming interface
 * @export
 */
export const MultipollApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MultipollApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a new multipoll.
         * @param {CreateMultipollRequest} createMultipollRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMultipoll(createMultipollRequest: CreateMultipollRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateMultipollResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMultipoll(createMultipollRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Multipoll public info.
         * @param {string} multipollId The ID of the multipoll.
         * @param {string} [answerToken] The authentication token for a multipoll answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMultipollInfo(multipollId: string, answerToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMultipollInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMultipollInfo(multipollId, answerToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit multipoll input.
         * @param {string} multipollId The ID of the multipoll.
         * @param {SubmitMultipollRequest} submitMultipollRequest 
         * @param {string} [answerToken] The authentication token for a multipoll answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitMultipoll(multipollId: string, submitMultipollRequest: SubmitMultipollRequest, answerToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitMultipollResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitMultipoll(multipollId, submitMultipollRequest, answerToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MultipollApi - factory interface
 * @export
 */
export const MultipollApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MultipollApiFp(configuration)
    return {
        /**
         * Create a new multipoll.
         * @param {CreateMultipollRequest} createMultipollRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMultipoll(createMultipollRequest: CreateMultipollRequest, options?: any): AxiosPromise<CreateMultipollResponse> {
            return localVarFp.createMultipoll(createMultipollRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Multipoll public info.
         * @param {string} multipollId The ID of the multipoll.
         * @param {string} [answerToken] The authentication token for a multipoll answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMultipollInfo(multipollId: string, answerToken?: string, options?: any): AxiosPromise<GetMultipollInfoResponse> {
            return localVarFp.getMultipollInfo(multipollId, answerToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit multipoll input.
         * @param {string} multipollId The ID of the multipoll.
         * @param {SubmitMultipollRequest} submitMultipollRequest 
         * @param {string} [answerToken] The authentication token for a multipoll answer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitMultipoll(multipollId: string, submitMultipollRequest: SubmitMultipollRequest, answerToken?: string, options?: any): AxiosPromise<SubmitMultipollResponse> {
            return localVarFp.submitMultipoll(multipollId, submitMultipollRequest, answerToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MultipollApi - object-oriented interface
 * @export
 * @class MultipollApi
 * @extends {BaseAPI}
 */
export class MultipollApi extends BaseAPI {
    /**
     * Create a new multipoll.
     * @param {CreateMultipollRequest} createMultipollRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultipollApi
     */
    public createMultipoll(createMultipollRequest: CreateMultipollRequest, options?: AxiosRequestConfig) {
        return MultipollApiFp(this.configuration).createMultipoll(createMultipollRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Multipoll public info.
     * @param {string} multipollId The ID of the multipoll.
     * @param {string} [answerToken] The authentication token for a multipoll answer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultipollApi
     */
    public getMultipollInfo(multipollId: string, answerToken?: string, options?: AxiosRequestConfig) {
        return MultipollApiFp(this.configuration).getMultipollInfo(multipollId, answerToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit multipoll input.
     * @param {string} multipollId The ID of the multipoll.
     * @param {SubmitMultipollRequest} submitMultipollRequest 
     * @param {string} [answerToken] The authentication token for a multipoll answer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MultipollApi
     */
    public submitMultipoll(multipollId: string, submitMultipollRequest: SubmitMultipollRequest, answerToken?: string, options?: AxiosRequestConfig) {
        return MultipollApiFp(this.configuration).submitMultipoll(multipollId, submitMultipollRequest, answerToken, options).then((request) => request(this.axios, this.basePath));
    }
}


