import Vue from 'vue';
import VueCookies from 'vue-cookies';
import Vuelidate from 'vuelidate';
import { BookingApi } from '@/api/booking';
import { PortalApi } from '@/api/portal';
import { MultipollApi } from '@/api/multipoll';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import AuthPlugin from './plugins/auth';

Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(AuthPlugin);

Vue.config.productionTip = false;

Vue.prototype.$apiBooking = new BookingApi(undefined, process.env.VUE_APP_API_URL);
Vue.prototype.$apiPortal = new PortalApi(undefined, process.env.VUE_APP_API_URL);
Vue.prototype.$apiMultipoll = new MultipollApi(undefined, process.env.VUE_APP_API_URL);

const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

axios.interceptors.request.use(async config => {
  const result = config;

  if (result.headers && vue.$auth.isAuthenticated()) {
    const token = await vue.$auth.getOrRefresh();
    result.headers.Authorization = `Bearer ${token}`;
  }

  return result;
});

axios.interceptors.response.use(response => response, error => {
  console.log(`error status=${error?.response?.status}`, JSON.stringify(error));
  if (error?.response?.status === 401) {
    vue.$router.replace({ name: 'auth.relogin' }).then(() => console.log('Redirecting to relogin'));
  }
  return Promise.reject(error);
});
