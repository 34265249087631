import _Vue from 'vue';
import ClientOAuth2 from 'client-oauth2';
import { AUTH_COOKIE_REFRESH_TOKEN } from '@/views/Auth/Login.vue';

const client: ClientOAuth2 = new ClientOAuth2({
  clientId: '1qg7rigmpdf70s5uf8tvublv7p',
  accessTokenUri: 'https://auth.dev.brontly.com/oauth2/token',
  authorizationUri: 'https://auth.dev.brontly.com/oauth2/authorize',
  redirectUri: 'http://localhost/auth/callback',
  scopes: ['com.brontly.dev.api/user', 'openid', 'email'],
});

let token: ClientOAuth2.Token | undefined;

export interface AuthPluginType {
  hasRefreshToken(): boolean,

  createAndRefresh(): Promise<void>,

  getAuthenticationUri(): string,

  initializeCallbackToken(originalUrl: string): Promise<void>,

  isAuthenticated(): boolean,

  getOrRefresh(): Promise<string>,
}

export default function AuthPlugin(Vue: typeof _Vue): void {
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$auth = {
    isAuthenticated(): boolean {
      return !!token;
    },
    hasRefreshToken(): boolean {
      return !!Vue.$cookies.get(AUTH_COOKIE_REFRESH_TOKEN);
    },
    getAuthenticationUri(): string {
      return client.code.getUri({
        query: {
          identity_provider: 'Google',
        },
      });
    },
    async initializeCallbackToken(originalUrl: string): Promise<void> {
      token = await client.code.getToken(originalUrl);
      Vue.$cookies.set(AUTH_COOKIE_REFRESH_TOKEN, token.refreshToken);
    },
    createAndRefresh(): Promise<void> {
      const cookieToken = client.createToken({
        refresh_token: Vue.$cookies.get(AUTH_COOKIE_REFRESH_TOKEN),
      });
      return cookieToken.refresh({
        body: {
          client_id: '1qg7rigmpdf70s5uf8tvublv7p',
        },
      })
        .then(refreshedToken => {
          console.log('Successfully refreshed the auth token');
          this.token = refreshedToken;
        });
    },
    async getOrRefresh(): Promise<string> {
      if (token) {
        if (token.expired()) {
          token = await token.refresh({
            body: {
              client_id: '1qg7rigmpdf70s5uf8tvublv7p',
            },
          });
        }
        return Promise.resolve(token.accessToken);
      }

      throw new Error('Token not available');
    },
  };
}
