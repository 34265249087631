import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'home',
    redirect: { name: 'portal.calendar.show' },
  }, {
    path: '/p',
    name: 'portal',
    components: {
      layout: () => import(/* webpackChunkName: 'group-booking' */ '@/layouts/Portal/PortalLayout.vue'),
    },
    children: [
      {
        path: 'calendar',
        name: 'portal.calendar.show',
        components: {
          view: () => import(/* webpackChunkName: 'group-booking' */ '@/views/Portal/Calendar/CalendarView.vue'),
        },
      }, {
        path: 'appointment-types',
        name: 'portal.appointment-types.index',
        components: {
          view: () => import(/* webpackChunkName: 'group-booking' */ '@/views/Portal/AppointmentTypes/AppointmentTypesView.vue'),
        },
      }, {
        path: 'appointment-types/:appointmentTypeId',
        name: 'portal.appointment-types.show',
        components: {
          view: () => import(/* webpackChunkName: 'group-booking' */ '@/views/Portal/AppointmentType/AppointmentTypeView.vue'),
        },
      }, {
        path: 'business',
        name: 'portal.business.show',
        components: {
          view: () => import(/* webpackChunkName: 'group-booking' */ '@/views/Portal/Business/BusinessView.vue'),
        },
      }, {
        path: 'account',
        name: 'portal.account.show',
        components: {
          view: () => import(/* webpackChunkName: 'group-booking' */ '@/views/Portal/Account/AccountView.vue'),
        },
      },
    ],
  }, {
    path: '/b',
    name: 'booking',
    components: {
      layout: () => import(/* webpackChunkName: 'group-booking' */ '@/layouts/Public/Booking/BookingLayout.vue'),
    },
    children: [
      {
        path: ':businessId',
        name: 'booking.show',
        components: {
          view: () => import(/* webpackChunkName: 'group-booking' */ '@/views/Booking/App.vue'),
        },
      },
      {
        path: ':businessId/appointment/:appointmentId',
        name: 'booking.appointment.show',
        components: {
          view: () => import(/* webpackChunkName: 'group-booking' */ '@/views/Booking/Appointment/AppointmentInfo.vue'),
        },
      },
    ],
  }, {
    path: '/multipoll',
    name: 'multipoll',
    components: {
      layout: () => import(/* webpackChunkName: 'group-multipoll' */ '@/layouts/Public/Multipoll/MultipollLayout.vue'),
    },
    children: [
      {
        path: 'create',
        name: 'multipoll.create',
        components: {
          view: () => import(/* webpackChunkName: 'group-multipoll' */ '@/views/Multipoll/MultipollCreate.vue'),
        },
      }, {
        path: ':multipollId/edit',
        name: 'multipoll.edit',
        components: {
          view: () => import(/* webpackChunkName: 'group-multipoll' */ '@/views/Multipoll/MultipollEdit.vue'),
        },
      }, {
        path: ':multipollId/summary',
        name: 'multipoll.summary',
        components: {
          view: () => import(/* webpackChunkName: 'group-multipoll' */ '@/views/Multipoll/MultipollSummary.vue'),
        },
      }, {
        path: ':multipollId',
        name: 'multipoll.submit',
        components: {
          view: () => import(/* webpackChunkName: 'group-multipoll' */ '@/views/Multipoll/MultipollSubmit.vue'),
        },
      }, {
        path: ':multipollId/edit/:answerToken',
        name: 'multipoll.answer.token',
        components: {
          view: () => import(/* webpackChunkName: 'group-multipoll' */ '@/views/Multipoll/MultipollAnswerTokenRedirect.vue'),
        },
      },
    ],
  }, {
    path: '/auth',
    name: 'auth',
    components: {
      layout: () => import(/* webpackChunkName: 'group-booking' */ '@/layouts/Public/Auth/AuthLayout.vue'),
    },
    children: [{
      path: 'login',
      name: 'auth.login',
      components: {
        view: () => import(/* webpackChunkName: 'group-auth' */ '@/views/Auth/Login.vue'),
      },
    }, {
      path: 'relogin',
      name: 'auth.relogin',
      components: {
        view: () => import(/* webpackChunkName: 'group-auth' */ '@/views/Auth/Login.vue'),
      },
    }, {
      path: 'logout',
      name: 'auth.logout',
      components: {
        view: () => import(/* webpackChunkName: 'group-auth' */ '@/views/Auth/Logout.vue'),
      },
    }, {
      path: 'callback',
      name: 'auth.callback',
      components: {
        view: () => import(/* webpackChunkName: 'group-auth' */ '@/views/Auth/Callback.vue'),
      },
    }],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
